import { render, staticRenderFns } from "./FlagValueTickbox.vue?vue&type=template&id=af137fce"
import script from "./FlagValueTickbox.vue?vue&type=script&setup=true&lang=ts"
export * from "./FlagValueTickbox.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Copy: require('/app/node_modules/@align/ui/dist/runtime/atoms/Copy/Copy.vue').default,TickBox: require('/app/node_modules/@align/ui/dist/runtime/atoms/TickBox/TickBox.vue').default})
